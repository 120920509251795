import React, { useCallback } from 'react';

import Sticky from 'react-sticky-el';
import Navigation from '../../components/Navigation/Navigation';
import LogoSVG from '../../images/logo.svg';
import { MobileMenuClickAction } from '../../state/actions';
import { useGlobalState } from '../../state/globalStateContext';
import links from '../../routing/links';

const NavigationBar = () => {
  const [, dispatch] = useGlobalState();

  const onClickHandler = useCallback(() => {
    dispatch(MobileMenuClickAction());
  }, [dispatch]);

  return (
    <Sticky stickyStyle={{
      boxShadow: 'rgba(0, 0, 0, 0.69) 0px 14px 20px -27px',
      zIndex: '1',
    }}
    >
      <Navigation links={links} logo={LogoSVG} onHamburgerClick={onClickHandler} />
    </Sticky>
  );
};

export default NavigationBar;
