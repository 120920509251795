import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'twin.macro';
import BaseImage from '../Image/Image';
import NavigationBar from './NavigationBar';
import NavigationLinks from './NavigationLinks/NavigationLinks';
import NavigationLink from './NavigationLinks/NavigationLink';
import config from '../../../tailwind.config';
import HomeLink from './HomeLink';
import MenuIcon from './MenuIcon';
import { useGlobalState } from '../../state/globalStateContext';

const Image = styled(BaseImage)`
    ${tw`w-8`}
`;

const Navigation = ({ links, logo, onHamburgerClick }) => {
  const [{ mobileMenuOpen }] = useGlobalState();
  return (
    <NavigationBar>
      <HomeLink to="/"><Image src={logo} /></HomeLink>
      <NavigationLinks>
        {links.map((link) => (
          <NavigationLink
            to={link.to}
            activeStyle={{
              color: config.theme.extend.colors.gold,
            }}
          >
            {link.label}

          </NavigationLink>
        ))}
      </NavigationLinks>
      <MenuIcon onClick={onHamburgerClick} mobileMenuOpen={mobileMenuOpen} />
    </NavigationBar>
  );
};

Navigation.defaultProps = {
  onHamburgerClick: () => {},
};

Navigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  logo: PropTypes.any.isRequired,
  onHamburgerClick: PropTypes.func,
};

export default Navigation;
