/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useEffect, useRef,
} from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { TimelineMax } from 'gsap';

const Hamburger = styled.div`
${tw`md:hidden cursor-pointer`}
`;

const MenuIcon = ({ onClick, mobileMenuOpen }) => {
  const top = useRef(null);
  const middle = useRef(null);
  const bottom = useRef(null);
  const menuToggle = useRef(null);

  useEffect(() => {
    menuToggle.current = new TimelineMax({ paused: true, reversed: true });
    menuToggle.current
      .add('translate')
      .to(top.current, { duration: 0.2, y: '5.735px' }, 'translate')
      .to(bottom.current, { duration: 0.2, y: '5.735px' }, 'translate')
      .to(middle.current, { duration: 0.2, scale: 0 }, 'translate')
      .add('rotate', 0.3)
      .to(top.current, { duration: 0.2, rotationZ: 45, transformOrigin: '50% 50%' }, 'rotate')
      .to(bottom.current, { duration: 0.2, rotationZ: -45, transformOrigin: '50% 50%' }, 'rotate');
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    mobileMenuOpen ? menuToggle.current.play() : menuToggle.current.reverse();
  }, [menuToggle, mobileMenuOpen]);

  return (
    <Hamburger onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 23.008 12.97">
        <g id="Barres" transform="translate(0 0.75)">
          <line ref={top} data-name="Ligne 4" x2="23.008" transform="translate(0 0)" fill="none" stroke="#13191f" strokeWidth="1.5" />
          <line ref={middle} data-name="Ligne 5" x2="23.008" transform="translate(0 5.735)" fill="none" stroke="#13191f" strokeWidth="1.5" />
          <line ref={bottom} data-name="Ligne 6" x2="23.008" transform="translate(0 11.47)" fill="none" stroke="#13191f" strokeWidth="1.5" />
        </g>
      </svg>
    </Hamburger>
  );
};

export default MenuIcon;
