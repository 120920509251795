/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Header from '../Header/Header';

const Page = ({ seo, header, main }) => {
  const {
    title,
    description,
    canonical,
    image,
  } = seo;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={image.fixed.src} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <link rel="canonical" href={canonical} />
      </Helmet>
      <Header>
        <>
          {header()}
        </>
      </Header>
      <main>
        {main()}
      </main>
    </>
  );
};

Page.propTypes = {
  seo: PropTypes.any.isRequired,
  header: PropTypes.func.isRequired,
  main: PropTypes.func.isRequired,
};

export default Page;
